// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as $$Location from "../../../models/Location.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(image) {
  return Json_Encode$JsonCombinators.object([
              [
                "description",
                image.description
              ],
              [
                "active",
                image.active
              ]
            ]);
}

var Req = {
  toJson: toJson
};

function exec(locationId, imageId, image) {
  return Rest.$$fetch("/dashboard/locations/" + (ID.toString(locationId) + ("/images/" + ID.toString(imageId))), {
              NAME: "Patch",
              VAL: toJson(image)
            }, {
              NAME: "Json",
              VAL: $$Location.Edit.decoder
            }, undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
