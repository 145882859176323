// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as $$Location from "../../../models/Location.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(req) {
  return Json_Encode$JsonCombinators.object([
              [
                "name",
                req.name
              ],
              [
                "full_address",
                req.fullAddress
              ],
              [
                "street_address_1",
                req.streetAddress1
              ],
              [
                "city",
                req.city
              ],
              [
                "state",
                req.state
              ],
              [
                "zip",
                req.zip
              ],
              [
                "country",
                req.country
              ],
              [
                "continent",
                req.continent
              ],
              [
                "latitude",
                req.latitude
              ],
              [
                "longitude",
                req.longitude
              ],
              [
                "totalPowerMw",
                req.totalPowerMw
              ],
              [
                "powerDensity",
                req.powerDensity
              ],
              [
                "grossBuildingSize",
                req.grossBuildingSize
              ],
              [
                "grossColocationSpace",
                req.grossColocationSpace
              ],
              [
                "meta_title",
                req.metaTitle
              ],
              [
                "meta_description",
                req.metaDescription
              ],
              [
                "nearest_airport_override",
                req.nearestAirportOverride
              ]
            ]);
}

var Req = {
  toJson: toJson
};

function exec(locationDraftId, $$location) {
  var url = "/dashboard/location_drafts/" + ID.toString(locationDraftId) + "/update_general_info";
  return Rest.$$fetch(url, {
              NAME: "Patch",
              VAL: toJson($$location)
            }, {
              NAME: "Json",
              VAL: $$Location.Edit.decoder
            }, undefined, undefined);
}

var GeneralInfo = {
  Req: Req,
  exec: exec
};

function toJson$1(req) {
  return Json_Encode$JsonCombinators.object([[
                "description",
                req.description
              ]]);
}

var Req$1 = {
  toJson: toJson$1
};

function exec$1(locationDraftId, $$location) {
  var url = "/dashboard/location_drafts/" + ID.toString(locationDraftId);
  return Rest.$$fetch(url, {
              NAME: "Patch",
              VAL: toJson$1($$location)
            }, {
              NAME: "Json",
              VAL: $$Location.Edit.decoder
            }, undefined, undefined);
}

var About = {
  Req: Req$1,
  exec: exec$1
};

export {
  GeneralInfo ,
  About ,
}
/* ID Not a pure module */
