// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as $$Location from "../../../models/Location.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function exec(pageNum, countryId, stateId, cityId, sortBy, query, providerIds) {
  var page = "page=" + String(pageNum);
  var countryQ = countryId !== undefined ? "&country_id=" + ID.toString(Caml_option.valFromOption(countryId)) : "";
  var stateQ = stateId !== undefined ? "&state_id=" + ID.toString(Caml_option.valFromOption(stateId)) : "";
  var cityQ = cityId !== undefined ? "&city_id=" + ID.toString(Caml_option.valFromOption(cityId)) : "";
  var providers = providerIds !== undefined && providerIds.length !== 0 ? "&" + (function (__x) {
          return Js_array.joinWith("&", __x);
        })(Belt_Array.map(providerIds, (function (id) {
                return "provider_ids[]=" + ID.toString(id);
              }))) : "";
  var query$1 = Belt_Option.getWithDefault(Belt_Option.map(query, (function (x) {
              return "&query=" + encodeURIComponent(x);
            })), "");
  var sortBy$1;
  if (sortBy !== undefined) {
    var variant = sortBy.NAME;
    sortBy$1 = variant === "Provider" ? "&sort_by=provider_name&sort_direction=" + $$Location.SortDirection.toString(sortBy.VAL) : (
        variant === "State" ? "&sort_by=state&sort_direction" + $$Location.SortDirection.toString(sortBy.VAL) : (
            variant === "Popular" ? "&sort_by=ahoy_events_count&sort_direction=" + $$Location.SortDirection.toString(sortBy.VAL) : (
                variant === "City" ? "&sort_by=city&sort_direction=" + $$Location.SortDirection.toString(sortBy.VAL) : "&sort_by=country&sort_direction=" + $$Location.SortDirection.toString(sortBy.VAL)
              )
          )
      );
  } else {
    sortBy$1 = "";
  }
  return Rest.$$fetch("/locations?" + page + providers + sortBy$1 + query$1 + countryQ + stateQ + cityQ, "Get", {
              NAME: "Json",
              VAL: $$Location.Index.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* ID Not a pure module */
