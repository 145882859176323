// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as Video from "../../../models/Video.res.js";
import * as $$Location from "../../../models/Location.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(req) {
  return Json_Encode$JsonCombinators.object([
              [
                "public_id",
                req.video.info.publicId
              ],
              [
                "id",
                req.video.info.id
              ],
              [
                "width",
                req.video.info.width
              ],
              [
                "height",
                req.video.info.height
              ],
              [
                "format",
                req.video.info.format
              ],
              [
                "url",
                req.video.info.secureUrl
              ],
              [
                "source",
                Video.Source.toString("Cloudinary")
              ]
            ]);
}

var Req = {
  toJson: toJson
};

function exec(locationId, video) {
  return Rest.$$fetch("/dashboard/locations/" + (ID.toString(locationId) + "/videos"), {
              NAME: "Post",
              VAL: toJson(video)
            }, {
              NAME: "Json",
              VAL: $$Location.Edit.decoder
            }, undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
