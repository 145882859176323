// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CreateLocationRequest from "./requests/CreateLocationRequest.res.js";
import * as ApproveLocationRequest from "./requests/ApproveLocationRequest.res.js";
import * as DestroyLocationRequest from "./requests/DestroyLocationRequest.res.js";
import * as ActivateLocationRequest from "./requests/ActivateLocationRequest.res.js";
import * as DeactivateLocationRequest from "./requests/DeactivateLocationRequest.res.js";
import * as CreateLocationImageRequest from "./requests/CreateLocationImageRequest.res.js";
import * as CreateLocationVideoRequest from "./requests/CreateLocationVideoRequest.res.js";
import * as DeleteLocationImageRequest from "./requests/DeleteLocationImageRequest.res.js";
import * as DeleteLocationVideoRequest from "./requests/DeleteLocationVideoRequest.res.js";
import * as FetchLocationsIndexRequest from "./requests/FetchLocationsIndexRequest.res.js";
import * as UpdateLocationDraftRequest from "./requests/UpdateLocationDraftRequest.res.js";
import * as UpdateLocationImageRequest from "./requests/UpdateLocationImageRequest.res.js";
import * as UpdateLocationVideoRequest from "./requests/UpdateLocationVideoRequest.res.js";
import * as FetchRelatedLocationsRequest from "./requests/FetchRelatedLocationsRequest.res.js";
import * as FetchLocationDashboardRequest from "./requests/FetchLocationDashboardRequest.res.js";
import * as FetchLocationsCityShowRequest from "./requests/FetchLocationsCityShowRequest.res.js";
import * as FetchLocationsDashboardRequest from "./requests/FetchLocationsDashboardRequest.res.js";
import * as ActivateLocationsInBatchRequest from "./requests/ActivateLocationsInBatchRequest.res.js";
import * as UpdateLocationImageActiveRequest from "./requests/UpdateLocationImageActiveRequest.res.js";
import * as UpdateLocationVideoActiveRequest from "./requests/UpdateLocationVideoActiveRequest.res.js";

var updateDraftGeneralInfo = UpdateLocationDraftRequest.GeneralInfo.exec;

var updateDraftAbout = UpdateLocationDraftRequest.About.exec;

var City = {
  show: FetchLocationsCityShowRequest.exec
};

var dashboard = FetchLocationsDashboardRequest.exec;

var create = CreateLocationRequest.exec;

var destroy = DestroyLocationRequest.exec;

var activate = ActivateLocationRequest.exec;

var deactivate = DeactivateLocationRequest.exec;

var activateInBatch = ActivateLocationsInBatchRequest.exec;

var fetchLocationDashboard = FetchLocationDashboardRequest.exec;

var approve = ApproveLocationRequest.exec;

var createImage = CreateLocationImageRequest.exec;

var createVideo = CreateLocationVideoRequest.exec;

var deleteImage = DeleteLocationImageRequest.exec;

var deleteVideo = DeleteLocationVideoRequest.exec;

var updateImage = UpdateLocationImageRequest.exec;

var updateVideo = UpdateLocationVideoRequest.exec;

var updateImageActive = UpdateLocationImageActiveRequest.exec;

var updateVideoActive = UpdateLocationVideoActiveRequest.exec;

var fetchLocationsIndex = FetchLocationsIndexRequest.exec;

var relatedLocations = FetchRelatedLocationsRequest.exec;

export {
  dashboard ,
  create ,
  destroy ,
  activate ,
  deactivate ,
  activateInBatch ,
  fetchLocationDashboard ,
  approve ,
  updateDraftGeneralInfo ,
  updateDraftAbout ,
  createImage ,
  createVideo ,
  deleteImage ,
  deleteVideo ,
  updateImage ,
  updateVideo ,
  updateImageActive ,
  updateVideoActive ,
  fetchLocationsIndex ,
  relatedLocations ,
  City ,
}
/* CreateLocationRequest Not a pure module */
