// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as $$Location from "../../../models/Location.res.js";

function exec(pageNum, locationId) {
  var page = "page=" + String(pageNum);
  return Rest.$$fetch("/locations/" + ID.toString(locationId) + "/related_locations?" + page, "Get", {
              NAME: "Json",
              VAL: $$Location.RelatedLocations.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* ID Not a pure module */
