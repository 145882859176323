// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as $$Location from "../../../models/Location.res.js";

function exec(locationDraftId) {
  var url = "/dashboard/location_drafts/" + ID.toString(locationDraftId) + "/approve";
  return Rest.$$fetch(url, {
              NAME: "Patch",
              VAL: null
            }, {
              NAME: "Json",
              VAL: $$Location.Edit.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* ID Not a pure module */
