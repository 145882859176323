// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as $$Location from "../../../models/Location.res.js";

function exec(locationId, providerId) {
  return Rest.$$fetch("/dashboard/providers/" + ID.toString(providerId) + "/locations/" + ID.toString(locationId) + "/edit", "Get", {
              NAME: "Json",
              VAL: $$Location.Edit.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* ID Not a pure module */
