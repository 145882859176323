// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as $$Location from "../../../models/Location.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(req) {
  return Json_Encode$JsonCombinators.object([
              [
                "name",
                req.name
              ],
              [
                "full_address",
                req.fullAddress
              ],
              [
                "street_address_1",
                req.streetAddress1
              ],
              [
                "city",
                req.city
              ],
              [
                "state",
                req.state
              ],
              [
                "zip",
                req.zip
              ],
              [
                "country",
                req.country
              ],
              [
                "continent",
                req.continent
              ],
              [
                "latitude",
                req.latitude
              ],
              [
                "longitude",
                req.longitude
              ]
            ]);
}

var Req = {
  toJson: toJson
};

function exec(providerId, $$location) {
  var url = "/dashboard/providers/" + ID.toString(providerId) + "/locations";
  return Rest.$$fetch(url, {
              NAME: "Post",
              VAL: toJson($$location)
            }, {
              NAME: "Json",
              VAL: $$Location.Edit.decoder
            }, undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
