// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(req) {
  return Json_Encode$JsonCombinators.object([
              [
                "location_ids",
                ID.toJsonArray(req.locationIds)
              ],
              [
                "active",
                req.active
              ]
            ]);
}

var Req = {
  toJson: toJson
};

function exec(body) {
  return Rest.$$fetch("/dashboard/locations/activate_in_batch", {
              NAME: "Patch",
              VAL: toJson(body)
            }, "Empty", undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
